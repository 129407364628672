import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--dark"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"margin": "0 0 24px 0",
			"children": "FAQs"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap-reverse",
			"justify-content": "space-around"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "25%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Які документи потрібні для вступу?"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Для вступу на курс вам не потрібні жодні спеціальні документи. Все, що вам потрібно – це бажання вивчати англійську мову та готовність приділяти час навчанню. Ми пропонуємо пробне заняття, після якого ви зможете прийняти остаточне рішення про вступ."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Як проходять онлайн-заняття?"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Наші онлайн-заняття проводяться за допомогою сучасних платформ для відеоконференцій. Ви зможете займатися з викладачем у зручний для вас час та отримувати всі необхідні навчальні матеріали в електронному вигляді. Ми гарантуємо якісну освіту та підтримку під час всього курсу навчання."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Як записатися на курс?"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Щоб записатися на курс, вам потрібно зв'язатися з нашими менеджерами через телефон або електронну пошту, зазначені на сайті. Вони допоможуть вам обрати курс, що найбільше відповідає вашим потребам, та запишуть вас на пробне заняття. Також ви можете заповнити онлайн-форму на нашому сайті, і ми зв'яжемося з вами для уточнення деталей."
		}
	}
};

const Faq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text1")} />
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Box {...override("box6")}>
					<Text {...override("text5")} />
					<Text {...override("text6")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq, { ...Section,
	defaultProps,
	overrides
});
export default Faq;